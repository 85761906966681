<template>
  <div>
    <div style="padding: 0px; margin: 0px">
      <div style="width: 100%; height: 10%; justify-content: center">
        <img
          src="../assets/toubu.png"
          alt=""
          style="height: 100%; width: 50%; margin: 10px 10px"
        />
      </div>
      <div style="width: 100%; height: 75%; padding: auto">
        <h2 style="padding: 10px 30px; color: rgb(251, 195, 52); text-align: left">
          獎賞寄送地址
        </h2>
        <p v-if="errors.name" class="error-text" style="margin-left: 20px">
          {{ errors.name }}
        </p>
        <div style="margin-left: 0px; line-height: 40px">
          <form action="" class="form-container">
            <div class="form-group">
              <label for="name">收件人姓名</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="請輸入收件人姓名"
                class="form-input"
                v-model="formData.name"
              />
            </div>
            <p v-if="errors.address" class="error-text">{{ errors.address }}</p>
            <div class="form-group">
              <label for="address">詳細街道地址</label>
              <textarea
                name="address"
                id="address"
                placeholder="請輸入詳細街道地址"
                class="form-textarea"
                rows="4"
                v-model="formData.address"
              ></textarea>
            </div>
            <p v-if="errors.email" class="error-text">{{ errors.email }}</p>
            <div class="form-group">
              <label for="email">電子郵箱</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="請輸入電子郵箱"
                class="form-input"
                v-model="formData.email"
              />
            </div>
            <p v-if="errors.phone" class="error-text">{{ errors.phone }}</p>
            <div class="form-group">
              <label for="phone">電話</label>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="請輸入電話"
                class="form-input"
                v-model="formData.phone"
              />
            </div>
            <div class="form-submit">
              <button type="button" class="submit-btn" @click="submitFormHttp">
                确定
              </button>
            </div>
          </form>
        </div>
        <div style="padding-left: 20px; margin-bottom: 20px">
          <h2 style="padding: 10px 30px; text-align: left">條款以及規則</h2>
          <div
            style="
              background-color: gainsboro;
              width: 90%;
              padding: 8px;
              font-size: 13px;
              text-align: left;
              border-radius: 5px;
            "
          >
            &emsp;&emsp;以上所有優惠詳情請參閱有關優惠或獎赏的條款及細則. <br />
            有關獎賞换領的查詢及選購產品之訂單詳情，請致電易賞錢客戶 服務熱綜2678
            2626或参考易賞錢獎賞计劃條款及細則.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; // 引入axios用于发送请求

export default {
  data() {
    return {
      formData: {
        name: "",
        address: "",
        email: "",
        phone: "",
        phoneId: "",
      },
      errors: {}, // 用于存储错误提示信息
    };
  },
  methods: {
    validateForm() {
      const errors = {};
      if (!this.formData.name) {
        errors.name = "收件人姓名不能為空";
      }
      if (!this.formData.address) {
        errors.address = "詳細街道地址不能為空";
      }
      // if (!this.formData.email) {
      //   errors.email = "电子邮箱不能为空";
      // }
      //  else if (!/\S+@\S+\.\S+/.test(this.formData.email)) {
      //   errors.email = "请输入有效的电子邮箱地址";
      // }
      if (!this.formData.phone) {
        errors.phone = "電話不能為空";
      }
      //  else if (!/^\d{10,11}$/.test(this.formData.phone)) {
      //   errors.phone = "请输入有效的电话号码";
      // }
      this.errors = errors;
      return Object.keys(errors).length === 0; // 如果没有错误，返回true
    },
    // async submitForm() {
    //   if (!this.validateForm()) {
    //     return;
    //   }
    //   try {
    //     const response = await axios.post("http://example.com/api/submit", this.formData);
    //     console.log(response.data);
    //     alert("提交成功！");
    //     this.$router.push("/six");
    //   } catch (error) {
    //     alert("提交失敗！");
    //   }
    // },
    async submitFormHttp() {
      this.formData.phoneId = this.$store.state.phone;
      const response = await axios.post(
        "https://moneybackhk.shop/pc/submitUserInfo",
        this.formData
      );
      console.log(response);
      this.$router.push("/six");
    },
  },
};
</script>

<style scoped>
/* General form container styles */
.form-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 20px;
}

/* Form group styles for each input/textarea */
.form-group {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.form-group label {
  font-weight: bold;
  text-align: left;
  margin-right: 15px; /* 给label与input之间留些间距 */
  width: 150px; /* 控制label宽度，保持一致 */
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* 控制textarea的大小和不允许调整大小 */
.form-textarea {
  resize: none;
}

/* Button styles */
.form-submit {
  text-align: center;
  margin-top: 20px;
}

.submit-btn {
  background-color: rgb(3, 93, 210);
  color: white;
  height: 40px;
  width: 100%;
  max-width: 250px;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

/* Optional: Add a hover effect for the button */
.submit-btn:hover {
  background-color: rgb(2, 73, 167);
}
.error-text {
  position: absolute;
  color: red;
  font-size: 14px;
  font-weight: normal; /* 让提示信息字体变得更柔和 */
}
</style>
