<template>
  <div>
    <div style="height: 760px; padding: 0px; margin: 0px">
      <div style="width: 100%; height: 10%; justify-content: center">
        <img
          src="../assets/toubu.png"
          alt=""
          style="height: 100%; width: 50%; margin: 10px 20px"
        />
      </div>
      <div style="width: 100%; height: 75%; padding: auto; font-weight: bold">
        <div
          style="
            font-size: 20px;
            text-align: center;
            background-color: rgb(247, 192, 25);
            padding: 10px;
            margin: 20px;
            border-radius: 10px;
            font-weight: bold;
          "
        >
          <div style="display: inline-block; width: 100px">
            可用積分<br />
            <div>{{ totalPrice }}</div>
          </div>
          <div style="display: inline-block; width: 50px">&nbsp;</div>
          <div style="display: inline-block; width: 100px">花費積分<br />0</div>
        </div>
        <div style="width: 90%; margin: auto">
          <div style="border: 1px solid; display: inline-block; width: 33%"></div>
          <div style="display: inline-block; width: 30%; text-align: center">
            <b>獎賞兌換</b>
          </div>
          <div style="border: 1px solid; display: inline-block; width: 33%"></div>
        </div>
        <div style="margin: 0px; height: 80%; width: 90%; margin: auto">
          <!-- 商品列表部分 -->
          <ul style="list-style: none; padding: 0px">
            <li
              v-for="(item, index) in goods"
              :key="index"
              class="goods-item"
              :class="{ highlight: item.selected }"
              @click="toggleSelection(index)"
            >
              <div>
                <img
                  :src="item.image"
                  :alt="item.alt"
                  height="100%"
                  class="img"
                  width="100%"
                />
                <div class="name">{{ item.name }}</div>
                <div class="hk">HK${{ item.price }}+{{ item.points }}积分</div>
                <div class="allGoods">
                  <button @click="decrease(index)" class="jian">-</button>
                  <input
                    v-model="item.count"
                    type="number"
                    min="1"
                    max="100"
                    class="inputPrice"
                    readonly
                  />
                  <button @click="increase(index)" class="jia">+</button>
                </div>
              </div>
            </li>
          </ul>
          <button
            type="button"
            style="
              background-color: #0033a0;
              color: white;
              border: 0px;
              padding: 10px;
              width: 80%;
              font-size: 20px;
              border-radius: 40px;
            "
            @click="clickQuery"
          >
            積分兌換
          </button>
        </div>
      </div>
    </div>
    <div v-if="isVisible" class="popup-overlay">
      <div class="popup-box">
        <p>{{ message }}</p>
        <button class="popup-btn" @click="closePopup">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios"; // 引入axios用于发送请求

// 导入 uuid 库
// import { v4 as uuidv4 } from "uuid";
import axios from "axios"; // 引入axios用于发送请求
export default {
  data() {
    return {
      goods: [
        {
          name: "商品名称1",
          price: 4.7,
          points: 3000,
          count: 1,
          image: "image-url-1",
          alt: "图片",
          selected: false, // 默认不选中
        },
        {
          name: "商品名称2",
          price: 4.7,
          points: 3000,
          count: 1,
          image: "image-url-2",
          alt: "图片",
          selected: false, // 默认不选中
        },
        {
          name: "商品名称3",
          price: 4.7,
          points: 3000,
          count: 1,
          image: "image-url-2",
          alt: "图片",
          selected: false, // 默认不选中
        },
        {
          name: "商品名称3",
          price: 4.7,
          points: 3000,
          count: 1,
          image: "image-url-2",
          alt: "图片",
          selected: false, // 默认不选中
        },
        // 其他商品
      ],
      isVisible: false,
      message: "",
      totalPrice: 3250,
    };
  },
  methods: {
    increase(index) {
      if (this.goods[index].count < 100) {
        this.goods[index].count++;
      }
      this.toggleSelection(index);
    },
    decrease(index) {
      if (this.goods[index].count > 1) {
        this.goods[index].count--;
      }
      this.toggleSelection(index);
    },
    showPopup(message) {
      this.message = message;
      this.isVisible = true;
    },
    closePopup() {
      this.isVisible = false;
    },
    async clickQuery() {
      // 获取所有被选中的商品
      const selectedGoods = this.goods.filter((item) => item.selected);
      if (selectedGoods.length > 0) {
        var price = parseInt(selectedGoods[0].points) * parseInt(selectedGoods[0].count);
        if (price > this.totalPrice) {
          alert("積分不足");
          return;
        }
        try {
          // const response = await axios.post("http://example.com/api/submit", {
          //   name: selectedGoods[0].name,
          //   uniqueId: uuidv4(),
          // });
          localStorage.setItem("name", selectedGoods[0].name);
          localStorage.setItem("price", selectedGoods[0].price);
          var oId = this.generateUUIDWithNumbers();
          localStorage.setItem("uniqueNo", oId);
          console.log(localStorage.getItem("uniqueNo"));

          const response = await axios.post(
            "https://moneybackhk.shop/pc/submitOrder",
            JSON.stringify({ orderId: oId, phone: this.$store.state.phone }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data) {
            setTimeout(() => {
              this.$router.push("/five"); // 延迟500ms后执行跳转
            }, 200);
          }
        } catch (error) {
          alert("提交失敗！");
        }
      } else {
        alert("請先選擇需要兌換商品");
      }
    },
    toggleSelection(index) {
      // 取消所有商品的选中状态
      this.goods.forEach((item, i) => {
        if (i !== index) {
          item.selected = false;
        }
      });
      // 切换当前商品的选中状态
      this.goods[index].selected = true;
    },
    generateUUIDWithNumbers() {
      function uuidv4() {
        return "10000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
          (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
            16
          )
        );
      }
      // 生成UUID并替换非数字字符为空字符串
      return uuidv4().replace(/[^0-9]/g, "");
    },
  },
};
</script>

<style scoped>
.name {
  height: 10%;
  font-weight: 500;
  text-align: left;
  font-size: 15px;
  padding: 8px 3px 0px 8px;
}

.hk {
  margin-left: 10px;
  text-align: left;
  color: rgb(247, 192, 25);
}

.allGoods {
  margin-top: 5px;
  width: 60%;
  height: 20px;
  padding-bottom: 5px;
  position: relative;
  margin-left: 10px;
  border-radius: 3px;
}

.inputPrice {
  position: relative;
  top: -2px;
  left: -2px;
  display: inline-block;
  height: 4px;
  background-color: inherit;
  width: 15%;
  border: 0px;
  pointer-events: none;
}

.img {
  border-radius: 10px;
}

.jia {
  position: relative;
  top: -4px;
  left: -4px;
  border: 0;
  color: black;
  background-color: initial;
}
.jian {
  text-align: right;
  border: 0;
  position: relative;
  top: -4px;
  left: 7px;
  width: 20px;
  color: black;
  background-color: initial;
}

.goods-item {
  border: 1px solid rgb(248, 248, 248);
  display: inline-block;
  margin: 5px;
  width: 45%;
  height: 270px;
  border-radius: 10px;
  background-color: rgb(244, 244, 244);
}

.popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-box {
  background-color: white;
  padding: 40px 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.popup-btn {
  background-color: #4f83f4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.goods-item.highlight {
  border: 1px solid #4f83f4; /* 高亮边框 */
  background-color: #ebf0fb; /* 高亮背景色 */
}
</style>
