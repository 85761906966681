import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import router from './router'
import store from './store'

Vue.use(ElementUI); // 3.安装

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// var system = {};
// var p = navigator.platform;
// var u = navigator.userAgent;

// system.win = p.indexOf("Win") == 0;
// system.mac = p.indexOf("Mac") == 0;
// system.x11 = p == "X11" || p.indexOf("Linux") == 0;
// if (system.win || system.mac || system.xll) {
//   //如果是PC转
//   if (u.indexOf("Windows Phone") > -1) {
//     //win手机端
//   } else {
//     window.location.href = "https://www.baidu.com/";
//   }
// }
