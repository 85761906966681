<template>
  <div>
    <div style="padding: 0px; margin: 0px">
      <div style="width: 99%; height: 10%; justify-content: center">
        <img
          src="../assets/toubu.png"
          alt=""
          style="height: 99%; width: 50%; margin: 10px 20px"
        />
      </div>
      <div style="width: 99%; height: 75%; padding: auto">
        <div style="text-align: center; margin-top: 30px">
          <div style="display: inline-block"><img src="" alt="" /></div>
          <div style="font-weight: bold; font-size: 25px; display: inline-block">
            <img src="" alt="警告" height="20px" />積分有效期提示
          </div>
        </div>
        <div
          style="
            background-color: aliceblue;
            padding: 20px;
            border-radius: 20px;
            margin: 20px;
            line-height: 25px;
            font-size: 14px;
            text-align: left;
          "
        >
          尊敬的YSQ客户:{{ phone }}<br />
          &emsp;&emsp;「YSQ積分計劃」提示您，您的賬戶當前積分(3250
          分)，将於三個工作日内到期，爲避免影響，請及時兌换積分 奬賞。<br /><br />
          &emsp;&emsp;YSQ積分計劃為您带來最新、最精彩的惠，下载YSQ緊
          贴最新資訊，輕輕鬆鬆就可以赚取YSQ積分!積分可用於直接
          繳付賬單外，更可以兌换多種生活禮品包括超市禮券、咖啡現
          金券、儲值卡等。YSQ積分碼的條款及細則 除非另有规定，
          每個YSQ積分只能使用一次。合資格客戶可於商戶送YSQ
          分時所展示之條款及細則查閱其YSQ積分碼之指定兌换有效
          期。YSQ積分碼必須於指定兌换有效期前兌换積分。<br /><br />
          &emsp;&emsp;如有任何爭議，YSQ香港將保留一切最終决定。
        </div>
        <div style="width: 99%; height: 75%; padding: auto">
          <div style="width: 99%; text-align: center; padding: 20px 10px">
            <button
              style="
                background-color: #0033a0;
                color: white;
                border: 0px;
                padding: 10px;
                width: 80%;
                font-size: 20px;
                border-radius: 40px;
              "
              @click="clickQuery()"
            >
              積分兌換
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    // 通过 mapState 获取 phone
    ...mapState({
      phone: (state) => state.phone,
    }),
  },
  data() {
    return {};
  },
  methods: {
    clickQuery() {
      this.$router.push("/four");
    },
  },
};
</script>

<style scoped></style>
