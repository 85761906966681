import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TwoView from '../views/TwoView.vue'
import ThreeView from '../views/ThreeView.vue'
import FourView from '../views/FourView.vue'
import FiveView from '../views/FiveView.vue'
import SixView from '../views/SixView.vue'
import SevenView from '../views/SevenView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/two',
    name: 'two',
    component: TwoView
  },
  {
    path: '/three',
    name: 'three',
    component: ThreeView
  },
  {
    path: '/four',
    name: 'four',
    component: FourView
  },
  {
    path: '/five',
    name: 'five',
    component: FiveView
  }
  ,
  {
    path: '/six',
    name: 'six',
    component: SixView
  }
  ,
  {
    path: '/seven',
    name: 'seven',
    component: SevenView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
