<template>
  <div>
    <div style="height: 760px; padding: 0px; margin: 0px">
      <div style="width: 100%; height: 10%; justify-content: center">
        <img
          src="../assets/toubu.png"
          alt=""
          style="height: 100%; width: 50%; margin: 10px 20px"
        />
      </div>
      
      <div style="text-align: right; margin-right: 30px">
        <router-link to="/four">下一页</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
