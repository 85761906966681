import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    phone: "",
    socket: null,
    socketMassage: "",
  },
  mutations: {
    setPhone (state, phone) {
      state.phone = phone;
    },
    setSocket (state, socket) {
      state.socket = socket;
    },
  },
  actions: {
    updatePhone ({ commit }, phone) {
      commit("setPhone", phone);
    },
    createSocket ({ commit }) {
      // 确保只创建一个 WebSocket 连接
      if (!this.state.socket) {
        const socket = new WebSocket("ws://localhost:8080/ws/" + this.state.phone); // 代理到目标 WebSocket 服务
        socket.onopen = () => {
          socket.send("hello,server");
          console.log("WebSocket 连接已建立");
        };
        socket.onclose = () => {
          console.log("WebSocket 连接已关闭");
        };
        socket.onmessage = (event) => {
          this.state.socketMassage = event.data;
        };
        socket.onerror = (error) => {
          console.error("WebSocket 错误:", error);
        };
        commit("setSocket", socket); // 将 WebSocket 连接存储到 Vuex
      }
    },
    closeSocket ({ state }) {
      if (state.socket) {
        state.socket.close(); // 关闭 WebSocket 连接
      }
    },
  },
});
