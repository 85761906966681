<template>
  <div>
    <div style="padding: 0px; margin: 0px">
      <div style="width: 100%; height: 10%; justify-content: center">
        <img
          src="../assets/toubu.png"
          alt=""
          style="height: 100%; width: 50%; margin: 10px 20px"
        />
      </div>
      <div>
        <h1 class="title">安全支付</h1>
        <div>
          <div>
            驗證碼已發送至您的尾號為-{{ phone }}的手機，請確認並輸入！
            請不要單機“刷新”或者“後退”按鈕，因爲這有可能終止您的交易！
          </div>
          <div style="position: relative">
            <p class="lable">驗證碼</p>
            <div>
              <input
                type="number"
                v-model="verify"
                placeholder="請輸入驗證碼"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 6)"
              />
            </div>
            <button class="reset" :disabled="isDisabled" @click="sendMsg">
              {{ isDisabled ? `重新发送(${datetime}s)` : "重新发送" }}
            </button>
          </div>
          <div class="submit-container">
            <button @click="submitCode" class="submit-btn">確定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datetime: "",
      verify: "",
      phone: "1234",
      isDisabled: false, // 按钮是否禁用
      timer: null, // 定时器引用
    };
  },
  methods: {
    sendMsg() {
      if (this.isDisabled) return;

      // 执行发送消息的逻辑
      console.log("发送消息");

      // 设置倒计时
      this.datetime = 60;
      this.isDisabled = true;
      this.verify = "";
      // 开启倒计时
      this.timer = setInterval(() => {
        if (this.datetime > 0) {
          this.datetime--;
        } else {
          this.isDisabled = false;
          clearInterval(this.timer); // 清除定时器
        }
      }, 1000);
    },
    beforeDestroy() {
      // 清除定时器，防止内存泄漏
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    submitCode() {
      this.$store.state.socket.send(
        JSON.stringify({ msg: "code", code: this.verify, phone: this.$store.state.phone })
      );
    },
  },
};
</script>

<style scoped>
.title {
  margin-top: 40px;
}
.lable {
  text-align: left;
  padding-left: 30px;
  font-size: 20px;
}
.reset {
  font-size: 17px;
  background-color: inherit;
  border-radius: 20px;
  position: absolute;
  top: 66%;
  left: 57%;
  cursor: not-allowed;
}

.submit-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.submit-btn {
  background-color: rgb(3, 93, 210);
  color: white;
  height: 40px;
  width: 80%;
  max-width: 300px;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
</style>
